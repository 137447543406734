import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters as OffreConfigurationParameters,
    Configuration,
    OptionsApi,
    OptionsOptionRead,
} from '../../../openApi/ApiOffre';
import { AUTOCOMPLETE_KEY, OFFRE_REDUCER_NAME } from '../constants';

export type OfferOptionApiObject = OptionsOptionRead & Record<string, unknown>;

export type OfferOptions = OfferOptionApiObject;

export const OFFER_OPTIONS_SLICE_NAME = 'offer_options';

const mapperOfferOptions = function (offerOptions: OfferOptionApiObject) {
    return {
        ...offerOptions,
    } as OfferOptions;
};

export function createOfferOptionsSlice(configuration: OffreConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new OptionsApi(conf);

    const fetchOfferOptionsCollection: FetchCollectionData<OfferOptionApiObject> = ({
        pagination: { page, itemsPerPage },
        filters,
        xIdSociete,
    }) => {
        let domaineId;
        if (filters.domainId) {
            domaineId = filters.domainId as number;
        }

        return api
            .apiOptionsGetCollection({
                xIdSociete,
                page: page + 1,
                itemsPerPage,
                domaineId,
            })
            .then((res) => res.data)
            .catch((error) => {
                console.error(error);
            }) as Promise<ApiCollectionResponse<OfferOptionApiObject>>;
    };

    const fetchOfferOptionsItem: FetchItemDataType<OfferOptionApiObject> = ({
        idItem,
        // pagination,
        xIdSociete,
    }) =>
        api
            .apiOptionsIdGet({ id: String(idItem), xIdSociete })
            .then((res) => res.data) as Promise<OfferOptionApiObject>;

    const { slice: offerOptionsSlice, dataSourcesThunks: offerOptionsDataSourcesThunks } =
        SliceFactory.createSlice<OfferOptionApiObject, OfferOptions>(
            OFFER_OPTIONS_SLICE_NAME,
            OFFRE_REDUCER_NAME,
            [AUTOCOMPLETE_KEY],
            fetchOfferOptionsCollection,
            fetchOfferOptionsItem,
            mapperOfferOptions,
        );

    return { offerOptionsSlice, offerOptionsDataSourcesThunks };
}
