import {
    ArticlesJsonldArticleRead,
    OptionValueJsonldOptionValueRead,
} from '@europrocurement/l2d-domain/openApi/ApiOffre';
import {
    Badge,
    Button,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Typography,
} from '@mui/material';
import { Box, useTheme } from '@mui/system';
import * as React from 'react';
import { hexToRgba, formatPrice } from '@europrocurement/l2d-utils';

import {
    deleteIcon,
    FaOptionIcon,
    leftIcon,
    NamedIconsType,
    updateIcon,
} from '@europrocurement/l2d-icons';
import { ModelItem } from '@b2d/pages/Achats/models/types';
import { CustomDivider } from '@europrocurement/flexy-components';
import actionModel from '../../models/action';
import additionalFieldModel from '../../models/additionalField';
import articleModel from '../../models/article';
import documentModel from '../../models/document';
import { specificsArticleLabels } from '../../constants';

type OptionValueRowProps = {
    selectedId?: number;
    optionValue: OptionValueJsonldOptionValueRead;
    onRollback: (optionValue: OptionValueJsonldOptionValueRead) => void;
    onEdit: (optionValue: OptionValueJsonldOptionValueRead) => void;
    onDelete: (optionValue: OptionValueJsonldOptionValueRead) => void;
    rank: number;
};

/**
 * Option value row component :
 *
 * - Logic and types needs to be splitted  / cleaned due to form refactor to handle articles relationships overrides
 */

const OptionValueRow: React.FC<OptionValueRowProps> = function (props) {
    const theme = useTheme();
    const { optionValue, onEdit, onDelete, onRollback, rank, selectedId } = props;
    const { libelle, description, actions, articles, champsComplementaires, documents } =
        optionValue;

    const optionValueRelatedModels = [actions, articles, champsComplementaires, documents];
    const [onHover, setHover] = React.useState<boolean>(false);

    const opacityFilter = onHover ? 0.05 : 0.01;

    const isSelected = selectedId === optionValue.id;
    const getBackground = () => {
        const hexColor = isSelected ? theme.palette.primary.main : '#000000';
        return hexToRgba(hexColor, isSelected ? 0.4 : opacityFilter);
    };

    const getModel = (apiModelType: string) => {
        if (apiModelType === 'Relation Champ Complementaires') {
            return additionalFieldModel;
        }
        if (apiModelType === documentModel.type) {
            return documentModel;
        }
        if (apiModelType === actionModel.type) {
            return actionModel;
        }
        if (['Relation Articles', articleModel.type].includes(apiModelType)) {
            return articleModel;
        }

        return undefined;
    };

    /** Relationship api key / accessor */
    const getModelSubKey = (model) => {
        const { key } = model;
        switch (key) {
            case 'additional_field':
                return 'champComplementaire';
            case 'document':
                return 'typeDocument';
            case 'action':
                return 'action';
            case 'article':
                return 'article';
            default:
                return null;
        }
    };

    const getSpecificTypeFromArticleLabel = (label: string) => {
        if (label === specificsArticleLabels.section) {
            return specificsArticleLabels.section;
        }
        if (label === specificsArticleLabels.lineBreak) {
            return specificsArticleLabels.lineBreak;
        }
        return null;
    };

    const specificArticleTooltip = (specificType, label = '') => {
        switch (specificType) {
            case specificsArticleLabels.section:
                return (
                    <Typography
                        fontSize={13}
                        sx={{ fontWeight: 'bold', textDecoration: 'underline' }}
                    >
                        {label}
                    </Typography>
                );

            case specificsArticleLabels.lineBreak:
                return <CustomDivider />;
            default:
                return <p> - - - </p>;
        }
    };

    const priceWithUnit = (price: number) => `${formatPrice(price)} €`;

    const displayPrice = (overridedUnitPrice: number | null, originalPrice: number | null) => {
        if (overridedUnitPrice) {
            return priceWithUnit(overridedUnitPrice);
        }
        if (originalPrice) {
            return priceWithUnit(originalPrice);
        }

        return 'Non défini';
    };

    const getItemToolTip = (item, model) => {
        const subkey = getModelSubKey(model);
        const isArticle = model.key === 'article';
        const isAdvertType = item.article?.rubArticle === 'LIB';
        const articleLabel = item.article?.libelle;
        const isAdvertArticle = isArticle && isAdvertType;
        const specificType = getSpecificTypeFromArticleLabel(articleLabel);
        const isSpecificArticle = isAdvertArticle && specificType;
        const isNormalArticle = isArticle && !isSpecificArticle;

        if (!subkey) {
            return <p>- - -</p>;
        }

        if (isArticle) {
            if (isSpecificArticle) {
                return specificArticleTooltip(specificType, item.libelle);
            }
            const {
                quantite: quantity,
                tarif: overridedUnitPrice,
                libelle: overridedLabel,
                article: originalArticle,
            } = item;

            const { libelle: originalLabel, prixUnitaire: originalPrice } = originalArticle;
            return (
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Box sx={{ flex: '0 0 7em' }}>
                        <p>{overridedLabel ?? originalLabel}</p>
                    </Box>
                    {isNormalArticle && (
                        <>
                            <Box sx={{ marginLeft: '30px', marginRight: '15px', flex: '0 0 5em' }}>
                                {displayPrice(overridedUnitPrice, originalPrice)}
                            </Box>
                            <Box sx={{ marginX: '30px' }}>
                                <Badge
                                    badgeContent={quantity}
                                    color="secondary"
                                />
                            </Box>
                        </>
                    )}
                </Box>
            );
        }
        return <p>{model.getItemDefaultLabel(item[subkey])}</p>;
    };

    const articleHeader = (
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ marginBottom: '7px' }}
        >
            <Box sx={{ flex: '0 0 7em' }}>
                <Typography fontSize={14}>Nom</Typography>
            </Box>
            <Box sx={{ flex: '0 0 3em' }}>
                <Typography fontSize={14}>Prix unitaire</Typography>
            </Box>
            <Box sx={{ flex: '0 0 7em' }}>
                <Typography fontSize={14}>Quantité</Typography>
            </Box>
        </Box>
    );
    /** Format option value relationships displayed label on hover icon
     * @todo Function argument correct typing
     */
    const getTooltipContent = (model: ModelItem<unknown>, items: Record<string, unknown>[]) => (
        <Box
            padding="10px"
            sx={{ minWidth: '300px' }}
        >
            <Typography
                sx={{ marginBottom: '5px' }}
                fontSize={16}
            >
                {model.type} :
            </Typography>
            {model.key === 'article' && articleHeader}
            {items.map((item) => getItemToolTip(item, model))}
        </Box>
    );

    const toolTipButton = (
        tooltipContent: string,
        clickAction: () => void,
        icon: NamedIconsType,
    ) => (
        <Tooltip title={tooltipContent}>
            <Button
                color="secondary"
                aria-label="ajout"
                size="small"
                sx={{
                    height: 'fit-content',
                }}
                onClick={clickAction}
            >
                <FaOptionIcon
                    {...icon.props}
                    size="lg"
                />
            </Button>
        </Tooltip>
    );

    const getArticlesLength = (articleItems: Array<{ article: ArticlesJsonldArticleRead }>) =>
        articleItems.reduce((filtered: Array<{ article: ArticlesJsonldArticleRead }>, item) => {
            const isAdvertType = item.article.rubArticle === 'LIB';
            const label = item.article.libelle ?? '';
            const specificType = getSpecificTypeFromArticleLabel(label);
            const specific = isAdvertType && specificType;
            if (!specific) {
                filtered.push(item);
            }
            return filtered;
        }, []).length;

    return (
        <ListItem
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            style={{
                background: getBackground(),
                transition: 'background 0.3s',
                margin: '5px',
            }}
        >
            <ListItemIcon>{rank}</ListItemIcon>
            <Box
                display="flex"
                flexDirection="column"
            >
                <ListItemText
                    primary={libelle}
                    secondary={description}
                />
                <Box
                    display="flex"
                    sx={{ margin: '20px' }}
                >
                    {optionValueRelatedModels.map((relatedModels) => {
                        const hasModel = relatedModels && relatedModels.length > 0;

                        if (!relatedModels) {
                            return null;
                        }

                        if (hasModel) {
                            // Investigate typing
                            const modelType = (relatedModels[0] as { ['@type']: string })['@type'];
                            const model = getModel(modelType);
                            if (!model) {
                                return null;
                            }
                            const modelIcon = model.icon;
                            const tooltipContent = getTooltipContent(
                                model,
                                relatedModels as Record<string, unknown>[],
                            );
                            const isArticle = model.key === 'article';
                            const modelLength = isArticle
                                ? getArticlesLength(relatedModels)
                                : relatedModels.length;
                            return (
                                <Box margin="0px 15px">
                                    <Tooltip title={tooltipContent}>
                                        <Badge
                                            badgeContent={modelLength}
                                            color="primary"
                                        >
                                            <FaOptionIcon
                                                {...modelIcon.props}
                                                size="lg"
                                            />
                                        </Badge>
                                    </Tooltip>
                                </Box>
                            );
                        }
                        return null;
                    })}
                </Box>{' '}
            </Box>

            {isSelected
                ? toolTipButton('Annuler édition', () => onRollback(optionValue), leftIcon)
                : toolTipButton("Éditer l'élément", () => onEdit(optionValue), updateIcon)}

            <Tooltip title="Supprimer l'élément">
                <Button
                    color="secondary"
                    aria-label="ajout"
                    size="small"
                    sx={{
                        height: 'fit-content',
                    }}
                    onClick={() => onDelete(optionValue)}
                >
                    <FaOptionIcon
                        {...deleteIcon.props}
                        size="lg"
                    />
                </Button>
            </Tooltip>
        </ListItem>
    );
};

export default OptionValueRow;
