/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { documentsApi } from '@b2d/redux/subReducers/Offers';
import { legalAdvertApi } from '@b2d/redux/subReducers/AnnoncesLegales';
import offerCacheSlice from '@europrocurement/l2d-domain/reducers/offre/slices/offerCacheSlice';
import { AnnonceLegaleAnnonceLegaleAnnonceLegaleReadItem } from '@europrocurement/l2d-domain/openApi/ApiAnnoncesLegales';
import { departmentApi } from '@b2d/redux/subReducers/Geoloc';
import { DPartementDepartementRead } from '@europrocurement/l2d-domain/openApi/ApiGeoloc';
import { createFetchActions } from './createFetchActions';
import { GroupedSelectOptions } from '../forms/types';

const fetchActions = {
    documents: () => documentsApi.apiTypesDocumentsGetCollection({ itemsPerPage: 1000 }),
    subTypes: () =>
        legalAdvertApi.getListAnnoncesLegalesAnnonceLegaleCollection({ itemsPerPage: 1000 }),
    departments: () => departmentApi.apiDepartementsGetCollection({ itemsPerPage: 1000 }),
};

/** Offer cached data redux store managment  :
 * Set here all offer related data that's need to be fetched once at module loading (article specifics, departments options...)
 */
const useOfferCache = () => {
    const { useDocuments, useSubTypes, useDepartments } = createFetchActions(fetchActions);
    const { result: docResult, isLoaded: docLoaded, isFetching: docFetching } = useDocuments;
    const {
        result: subTypesResult,
        isLoaded: subTypesLoaded,
        isFetching: subTypesFetching,
    } = useSubTypes;

    const {
        result: departmentsResult,
        isLoaded: departmentsLoaded,
        isFetching: departmentsFetching,
    } = useDepartments;

    const dispatch = useDispatch();

    const newItem = ({ libelle, id }: AnnonceLegaleAnnonceLegaleAnnonceLegaleReadItem) => ({
        label: libelle ?? '',
        value: id,
    });

    const createCategory = (
        addedItem: AnnonceLegaleAnnonceLegaleAnnonceLegaleReadItem,
        list: GroupedSelectOptions,
    ) => {
        list.push({
            label: addedItem.categorie?.libelle ?? '',
            options: [newItem(addedItem)],
        });
    };

    const addSubTypeInCategory = (
        categoryLabel: string,
        addedItem: AnnonceLegaleAnnonceLegaleAnnonceLegaleReadItem,
        list: GroupedSelectOptions,
    ) => {
        const categoryIndex = list.findIndex((category) => category.label === categoryLabel);
        if (categoryIndex === -1) {
            addSubTypeInCategory('Autres', addedItem, list);
        } else {
            list[categoryIndex].options.push(newItem(addedItem));
        }
    };

    const defaultOptions = [
        {
            label: 'Autres',
            options: [],
        },
    ];

    const groupSubtypesByCategories = () => {
        const categories = (
            subTypesResult as AnnonceLegaleAnnonceLegaleAnnonceLegaleReadItem[]
        ).reduce((acc: GroupedSelectOptions, item) => {
            const itemCategory = item.categorie?.libelle;
            const categoryExist = acc.some(({ label }) => label === itemCategory);
            if (!itemCategory) {
                addSubTypeInCategory('Autres', item, acc);
            } else if (!categoryExist) {
                createCategory(item, acc);
            } else {
                addSubTypeInCategory(itemCategory, item, acc);
            }
            return acc;
        }, defaultOptions);
        return categories;
    };

    useEffect(() => {
        useDocuments.fetch();
        useSubTypes.fetch();
        useDepartments.fetch();
    }, []);

    useEffect(() => {
        if (docLoaded && !docFetching) {
            dispatch(offerCacheSlice.actions.updateCachedDocuments(docResult as Array<unknown>));
        }
    }, [docResult, docLoaded, docFetching]);

    useEffect(() => {
        if (subTypesLoaded && !subTypesFetching) {
            const cachedSubTypesOptions = groupSubtypesByCategories();
            dispatch(
                offerCacheSlice.actions.updateCachedSubTypes(
                    cachedSubTypesOptions as Array<unknown>,
                ),
            );
        }
    }, [subTypesResult, subTypesLoaded, subTypesFetching]);

    useEffect(() => {
        if (departmentsLoaded && !departmentsFetching) {
            const result = departmentsResult as Array<DPartementDepartementRead>;
            const departmentOptions = result.map(({ codgeo, libelle }) => ({
                value: codgeo,
                label: libelle,
            }));
            dispatch(offerCacheSlice.actions.updateCachedDepartments(departmentOptions));
        }
    }, [departmentsResult, departmentsLoaded, departmentsFetching]);
};
export default useOfferCache;
