import useApiRequest from '@b2d/hooks/useApiRequest';
import { customizerSelector, domainSelector } from '@b2d/redux/RootStore';
import { maximumLengthRule } from '@b2d/validation/rules/length';
import { FlexyForm, FormObject, FormStructure } from '@europrocurement/flexy-form';
import {
    OptionsJsonldOptionCreate,
    OptionsJsonldOptionUpdate,
    OptionsOptionRead,
} from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { Box, Button } from '@mui/material';
import React, { FC } from 'react';
import { Controller, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { ModalContext } from '@europrocurement/flexy-components';
import models from '@b2d/pages/Offres/models';

import { FaOptionIcon, offerOptionValueIcon, previewIcon } from '@europrocurement/l2d-icons';
import { AbstractFormType } from '../forms/types';
import { formatPayload } from '../forms/format';
import OptionValuesModule from './OptionValue/OptionValuesModule';
import { formatOptionsByKeys } from '../forms/options';
import SelectField from '../components/form/SelectField';

type OfferOptionProps = AbstractFormType & {
    entity?: OptionsOptionRead;
};

const Form: FC<OfferOptionProps> = function (props) {
    const { afterSubmit, entity = {}, isCreate = false } = props;
    const { modalActions } = React.useContext(ModalContext);
    const { offerOption } = models;

    const fetchedDomainsOptions = useSelector(domainSelector).main.data;
    const domainsOptions = formatOptionsByKeys(fetchedDomainsOptions, 'id', 'libelle');

    const MODE_LIST = 'list';
    const MODE_PREVIEW = 'preview';
    type ModuleMode = typeof MODE_LIST | typeof MODE_PREVIEW;

    const [moduleMode, setModuleMode] = React.useState<ModuleMode>(MODE_LIST);
    const [optionValueInEdition, setOptionValueInEdition] = React.useState<boolean | undefined>();

    const toggleModuleMode = () => {
        setModuleMode(moduleMode === MODE_LIST ? MODE_PREVIEW : MODE_LIST);
    };

    const successCallback = () => {
        modalActions.reset();
        afterSubmit();
    };

    const getInitialValues: () => FormObject = () => ({
        ...entity,
        domainId: entity.domaine?.id ?? null,
    });
    const initialValues = isCreate ? {} : getInitialValues();
    const formContext = useForm<FieldValues>({
        defaultValues: initialValues,
        mode: 'onTouched',
    });

    const {
        control,
        getValues,
        trigger,
        watch,
        formState: { errors },
    } = formContext;

    watch('domainId');
    watch('values');
    const { domainId, values } = getValues();

    const hasOptionValues: boolean = values && values.length > 0;
    const hasDomain: boolean = typeof domainId === 'number';

    const { xIdSociete: companyId } = useSelector(customizerSelector);

    const { request } = useApiRequest();
    const formHasOptionValues = values && values.length > 0;
    const formatOutputSchema = {
        htmlFieldType: (type: string) => type ?? '',
        values: (formValues: object[]) => formValues.map((value: { id: number }) => value.id), // Send only id and not whole option value
    };

    const formStructure: FormStructure[] = [
        {
            type: 'header',
            label: 'Description générale',
            sx: {
                marginTop: '0px',
            },
            name: 'info',
            xs: 12,
        },
        {
            type: 'text',
            name: 'libelle',
            inputlabel: "Libellé de l'option",
            onChange: () => trigger(),
            rules: {
                required: 'Vous devez renseigner un libellé',
            },
            xs: 7,
            placeholder: 'Nom de l`option',
        },
        {
            type: 'customItem',
            name: 'margin',
            xs: 1,
            renderField: () => <Box sx={{ marginLeft: '20px' }} />,
        },
        {
            type: 'boolean',
            name: 'visibleFront',
            inputlabel: 'Option visible',
            defaultValue: true,
            xs: 2,
            verticalSwitch: true,
        },
        {
            type: 'boolean',
            name: 'obligatoire',
            inputlabel: 'Option obligatoire',
            defaultValue: true,
            xs: 2,
            verticalSwitch: true,
        },
        {
            type: 'textarea',
            name: 'description',
            inputlabel: "Description de l'option",
            rules: {
                required: 'Vous devez renseigner un description',
                validate: {
                    ...maximumLengthRule(255),
                },
            },
            multiline: true,
            rows: 2,
            xs: 12,
            placeholder: "Description de l'option",
        },
        {
            type: 'customItem',
            name: 'domainId',
            rules: {
                required: 'Paramètre obligatoire',
            },
            renderField: () => (
                <Controller
                    name="domainId"
                    control={control}
                    render={({ field }) => (
                        <SelectField
                            {...field}
                            placeholder="Sélectionnez un domaine"
                            name={field.name}
                            isDisabled={formHasOptionValues || optionValueInEdition}
                            label="Domaine"
                            options={domainsOptions}
                            errors={errors}
                        />
                    )}
                />
            ),
        },
        {
            type: 'header',
            label: "Configuration de l'option",
            sx: {
                marginTop: '0px',
            },
            name: 'info',
            xs: 12,
        },
        {
            type: 'customItem',
            name: 'values',
            rules: {
                required: 'Paramètre obligatoire',
            },
            renderField: () =>
                !hasDomain ? (
                    <p>Veuillez selectionner un domaine</p>
                ) : (
                    <OptionValuesModule
                        isCreate={isCreate}
                        formContext={formContext}
                        companyId={companyId}
                        moduleMode={moduleMode}
                        domainId={domainId}
                        setOptionValueInEdition={setOptionValueInEdition}
                    />
                ),
        },
    ];

    const createModel: SubmitHandler<FormObject> = async (formValues) => {
        const formData = formatPayload(formValues, formatOutputSchema) as OptionsJsonldOptionCreate;

        const payload = {
            ...formData,
            domaine: domainId,
        };
        const createRequest = offerOption.create({
            optionsJsonldOptionCreate: payload,
            xIdSociete: companyId,
        });
        await request(createRequest, { successCallback });
    };

    const updateModel: SubmitHandler<FormObject> = async (formValues: { id: string }) => {
        const modelId = formValues.id;
        const formData = formatPayload(formValues, formatOutputSchema) as OptionsJsonldOptionUpdate;
        if (!modelId) {
            return;
        }
        const payload = {
            ...formData,
            domaine: domainId,
        };
        const updateRequest = offerOption.update({
            id: modelId,
            optionsJsonldOptionUpdate: payload,
            xIdSociete: companyId,
        });
        await request(updateRequest, { successCallback });
    };

    const handleSubmit = async () => {
        const validation = await trigger();
        const { htmlFieldType } = getValues();

        if (!validation) {
            return;
        }

        if (!htmlFieldType) {
            formContext.setValue('htmlFieldType', 'checkbox');
        }
        if (isCreate) {
            createModel(getValues());
        } else {
            updateModel(getValues());
        }
    };

    const modeToggleButton = (
        <Button
            sx={{ margin: '15px 15px 0px' }}
            type="submit"
            onClick={(event) => {
                event.preventDefault();
                toggleModuleMode();
            }}
            variant="outlined"
            startIcon={
                moduleMode === MODE_LIST ? (
                    <FaOptionIcon {...previewIcon.props} />
                ) : (
                    <FaOptionIcon {...offerOptionValueIcon.props} />
                )
            }
            disabled={!hasDomain || !hasOptionValues}
        >
            {moduleMode === MODE_LIST ? 'Afficher aperçu' : "Afficher liste d'options"}
        </Button>
    );

    const optionFormButtons = {
        render: () => (
            <Box
                display="flex"
                justifyContent="space-between"
                width="100%"
            >
                {modeToggleButton}
                <Button
                    sx={{ marginTop: '15px' }}
                    type="submit"
                    onClick={(event) => {
                        event.preventDefault();
                        handleSubmit();
                    }}
                    variant="contained"
                    disabled={!hasDomain || !hasOptionValues}
                >
                    Valider
                </Button>
            </Box>
        ),
    };

    return (
        <Box>
            <FlexyForm
                formContext={formContext}
                formObject={initialValues}
                formStructure={formStructure}
                onSubmit={handleSubmit}
                isDisabled={false}
                submitButton={optionFormButtons}
            />
        </Box>
    );
};

export default Form;
