import * as React from 'react';

import { Box } from '@mui/material';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import AsyncSelect, { AsyncSelectProps } from '@b2d/pages/Offres/components/form/AsyncSelect';
import AsyncEditableListItem, { EditableListItem } from './AsyncEditableItem';

export type AsyncEditableListProps = {
    defaultList?: EditableListItem[];
    formContext: UseFormReturn<FieldValues, unknown>;
    formKey: string;
    formatItem?: (item: EditableListItem) => unknown;
    resetField?: boolean;
    disabled?: boolean;
} & Omit<AsyncSelectProps, 'onSelectItem' | 'renderField'>;

const AsyncEditableList: React.FC<AsyncEditableListProps> = function (props) {
    const {
        formContext,
        formKey,
        inputLabel,
        required,
        placeholder,
        model,
        defaultList,
        formatItem,
        resetField,
        disabled = false,
    } = props;

    const [list, setList] = React.useState<EditableListItem[]>(defaultList ?? []);

    React.useEffect(() => {
        if (resetField) {
            setList(defaultList ?? []);
        }
    }, [resetField, defaultList]);

    React.useEffect(() => {
        const formItems = formatItem ? list.map((listItem) => formatItem(listItem)) : list;
        formContext.setValue(formKey, formItems);
    }, [formContext, formKey, formatItem, list]);

    const removeItem = (item: EditableListItem) => {
        setList(list.filter((listItem: EditableListItem) => listItem['@id'] !== item['@id']));
    };

    const addItem = (newItem: EditableListItem) => {
        const itemExistInList =
            list.filter((listItem: EditableListItem) => listItem['@id'] === newItem['@id']).length >
            0;
        if (itemExistInList) {
            return;
        }
        setList([...list, newItem]);
    };

    return (
        <>
            <AsyncSelect
                renderField=""
                inputLabel={inputLabel}
                onSelectItem={(item: EditableListItem) => addItem(item)}
                required={required}
                placeholder={placeholder}
                model={model}
                reset={resetField}
                disabled={disabled}
            />

            <Box>
                {list.map((item: EditableListItem) => (
                    <AsyncEditableListItem
                        model={model}
                        item={item}
                        removeItem={removeItem}
                    />
                ))}
            </Box>
        </>
    );
};

export default AsyncEditableList;
