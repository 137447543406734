import * as React from 'react';
import { Box } from '@mui/system';
import { FlexyHeaderForm, FlexySelect } from '@europrocurement/flexy-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMerge, faParagraph, faSearch, faText } from '@fortawesome/pro-solid-svg-icons';
import { CircularProgress, Typography } from '@mui/material';
import {
    ArticlesArticleRead,
    TblFactArticlesOptionValueRead,
    TblFactArticlesPackageRead,
} from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { useSelector } from 'react-redux';
import { RubriqueFacturationRubriqueFactureRead } from '@europrocurement/l2d-domain/openApi/ApiDossiers';
import { formatPrice } from '@europrocurement/l2d-utils';
import {
    InvoicingCategoriesSelector,
    lineBreakArticleId,
    sectionTitleArticleId,
} from '@b2d/redux/subReducers/Offers';
import SortableList from '../../components/SortableList/SortableList';
import { ArticlesSchema, PackageFormData } from '../Form/validation';
import { generateUUID } from '../Form/utils';
import EmptyLine from './EmptyLine';
import PackageLine from './PackageLine';
import { ArticleFormItem, FormContextType, Warning } from './types';
import TooltipedButton from '../../components/TooltipedButton';

import articleModel from '../../models/article';
import useFetchApiResource from '../Form/useFetchCollection';
import InvoicingCategoriesFilters from './InvoicingCategoriesFilters';
import useReadOnlyDocuments from '../../components/DocumentsList/useReadOnlyDocuments';

/** Description of an editable article */
export type EditableArticle = {
    id: string; // DraggableId (uuid)
    originalArticle: TblFactArticlesPackageRead | TblFactArticlesOptionValueRead; // Source article used to compare / infer overrides
    label: string | null; // Overrided label
    rank: number; // Rank from API
    articleId: number;
    price: string;
    quantity: number;
    subTypeId: number;
    invoicingCategory: number;
    priceInformations: {
        // Additional prices informations
        isFlatRate: boolean;
        flatPrice: string;
    };
};
/**
 * Component is too long / too much logic :
 * - Export types
 * - Split component logic (duplicates, ...)
 * - Split component render
 */

type ComponentProps = {
    formContext: FormContextType;
    isStepValidating: boolean;
    disabled?: boolean;
    domainId?: number;
    displayHeader?: boolean;
};

type InvoicingCategoriesFilters = {
    visible: false;
    filters: string[] | null; // Codes
};

/** List management & articles ranking, quantities, fields override */
const ArticlesModule: React.FC<ComponentProps> = function (props) {
    const {
        formContext,
        isStepValidating,
        disabled = false,
        domainId: parentFormDomainId,
        displayHeader = true,
    } = props;
    const sectionTitleId = useSelector(sectionTitleArticleId);
    const lineBreakId = useSelector(lineBreakArticleId);

    const {
        watch,
        trigger,
        formState: { errors },
    } = formContext;

    const { actions, formatUtils } = useReadOnlyDocuments(formContext);

    // Form change listener for rendering purpose
    watch('articles');

    // Transform ArticleRead to form article item
    const formatApiArticleToFormArticle: (
        articleFromApi: ArticlesArticleRead,
    ) => ArticleFormItem = (article) => ({
        id: generateUUID(), // DraggableId
        originalArticle: article,
        // Form
        articleId: article.id as number, // FIX api type can't be undefined
        label: null,
        price: null,
        quantity: 1,
        subTypeId: null,
        invoicingCategory: article.rubArticle ?? null,
    });

    const [filters, setFilters] = React.useState<string[]>([]);

    const invoicingCategoriesOptions = useSelector(InvoicingCategoriesSelector).main
        .data as RubriqueFacturationRubriqueFactureRead[];

    const { articles, domainId: selectedDomainIdInStepOne } =
        formContext.getValues() as PackageFormData;

    const codeIsFilterable = (code: string) => filters.includes(code);

    const stringIndexes = Object.keys(errors.articles ?? {});
    const errorIndexes = stringIndexes.map((stringIndex) => parseInt(stringIndex, 10));

    const articlesByDomain = () =>
        articleModel.list({
            domaineId: parentFormDomainId ?? selectedDomainIdInStepOne,
            itemsPerPage: 1000,
        });

    const {
        fetch: fetchArticlesByDomain,
        result: domainArticles,
        isLoaded: domainArticlesLoaded,
    } = useFetchApiResource<ArticlesArticleRead[]>(articlesByDomain);

    const getAvailableInvoicingCategories = () => {
        const articlesInvoicingCodes = domainArticles.map(
            (article: ArticlesArticleRead) => article.rubArticle,
        );
        return invoicingCategoriesOptions.filter((invoicingCategoriesOption) =>
            articlesInvoicingCodes.includes(invoicingCategoriesOption.code),
        );
    };

    const displayArticleCode = (code: string | null | undefined) => {
        if (code) {
            return `${code} -`;
        }
        return '';
    };

    const displayArticlePrice = (price: string | null | undefined) => {
        if (price) {
            return `${formatPrice(price)} €`;
        }
        return 'Aucun tarif défini';
    };
    const getOptionLabel = (article: ArticlesArticleRead) =>
        `${article.libelle} (${displayArticleCode(article.codeArticle)} ${displayArticlePrice(article.prixUnitaire)} )`;

    const articleOptions = () => {
        const groupedOptions = invoicingCategoriesOptions.reduce((acc, { code, libelle }) => {
            const invoiceArticles = domainArticles.filter(
                (article: ArticlesArticleRead) => article.rubArticle === code,
            );

            if (code && code !== 'LIB' && codeIsFilterable(code)) {
                return [
                    ...acc,
                    {
                        label: libelle,
                        options: invoiceArticles.map((article: ArticlesArticleRead) => ({
                            label: getOptionLabel(article),
                            value: article,
                        })),
                    },
                ];
            }
            return acc;
        }, []);

        return groupedOptions;
    };

    React.useEffect(() => {
        // On mount fetch articles from selected domain
        fetchArticlesByDomain();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // TriggerValidation on article change
    React.useEffect(() => {
        if (articles.length > 0) {
            trigger();
        }
    }, [articles, trigger]);

    const emptyList = articles.length === 0;
    const setArticles = (values: ArticlesSchema['articles']) => {
        formContext.setValue('articles', values);
    };

    const addNewArticle = (newArticle: ArticleFormItem, appendTop?: boolean) => {
        if (appendTop) {
            const articlesCopy = [...articles];
            setArticles([newArticle, ...articlesCopy]);
        } else {
            setArticles([...articles, newArticle]);
        }

        const articleDocuments = newArticle?.originalArticle?.documents;
        const documentItems = formatUtils.formatArticleDocumentItems(
            articleDocuments,
            newArticle.originalArticle,
        );

        actions.addDocumentsInCategory('articles', documentItems);
    };

    const onSelectNewArticle = (article: ArticlesArticleRead) => {
        const newArticle = formatApiArticleToFormArticle(article);
        addNewArticle(newArticle);
    };

    /** Compare article fields to determine if it's a duplicated article */
    const isDuplicate: (article: ArticleFormItem) => boolean = (article) => {
        const sameOrBothEmpty = (value: unknown, valueKey: keyof ArticleFormItem): boolean => {
            const sameValue = value === article[valueKey];
            const bothEmpty = !value && !article[valueKey];

            return sameValue || bothEmpty;
        };

        const duplicateArticles = articles.filter(
            ({ id, articleId, label, price, invoicingCategory, subTypeId }) => {
                const excludeCurrentArticle = id !== article.id;
                const sameId = articleId === article.articleId;
                const labelSpecificArticle = invoicingCategory === 'LIB';

                return (
                    !labelSpecificArticle &&
                    excludeCurrentArticle &&
                    sameId &&
                    sameOrBothEmpty(label, 'label') &&
                    sameOrBothEmpty(price, 'price') &&
                    sameOrBothEmpty(subTypeId, 'subTypeId')
                );
            },
        );

        return duplicateArticles.length > 0;
    };

    const duplicatedArticles = articles.filter((article) => isDuplicate(article));
    const hasDuplicates = duplicatedArticles.length > 0;

    const mergeArticles = (...articlesToMerge: ArticleFormItem[]) => {
        let deletedIds: string[] = [];
        if (articlesToMerge.length > 0) {
            const updatedArticle = articlesToMerge[0];
            const mergeIndex = articles.findIndex((article) => article.id === updatedArticle.id);
            const updatedQuantity = articlesToMerge.reduce((acc, curr) => acc + curr.quantity, 0);
            const deletedArticles = articlesToMerge.filter((_item, index) => index !== 0);
            deletedIds = deletedArticles.map((item) => item.id);

            // Update keeped value
            formContext.setValue(`articles.${mergeIndex}`, {
                ...updatedArticle,
                quantity: updatedQuantity > 99 ? 99 : updatedQuantity,
            });
        }

        return deletedIds;
    };

    const deleteArticles = (articleIds: string[]) => {
        formContext.setValue(`articles`, [
            ...articles.filter((item) => articleIds.includes(item.id) === false),
        ]);
    };

    const duplicatesGroupedByArticleId = (arrayOfArticles: ArticleFormItem[]) => {
        const groupedByIds = arrayOfArticles.reduce(
            (acc, current) => {
                if (isDuplicate(current)) {
                    (acc[current.articleId.toString()] =
                        acc[current.articleId.toString()] || []).push(current);
                }
                return acc;
            },
            {} as Record<string, ArticleFormItem[]>,
        );

        return groupedByIds;
    };

    /** Merge all duplicated articles */
    const mergeDuplicates = () => {
        const duplicatesGroups = duplicatesGroupedByArticleId(articles);

        const idsToDelete: string[] = [];
        Object.entries(duplicatesGroups).forEach((value) => {
            const articlesToMerge = value[1];
            const ids = mergeArticles(...articlesToMerge);
            idsToDelete.push(...ids);
        });

        deleteArticles(idsToDelete);
    };

    /** Return article related warnings based on business logic */
    const getWarnings: (articleId: string) => Warning[] = (articleId) => {
        const currentArticle = articles.find((articleItem) => articleItem.id === articleId);

        const missingStype =
            currentArticle?.originalArticle?.rubArticle === 'PUB' && !currentArticle.subTypeId;
        const duplicateArticle = isDuplicate(currentArticle as ArticleFormItem);

        const emptySectionLabel =
            currentArticle?.invoicingCategory === 'LIB' && !currentArticle?.label;

        return [
            {
                condition: missingStype,
                name: 'missingSType',
                message: 'Veuillez selectionner un sous-type pour cet article',
            },
            {
                condition: duplicateArticle,
                name: 'duplicate',
                message: 'Cet article est dupliqué',
            },
            {
                condition: emptySectionLabel,
                name: 'emptySection',
                message: 'Le titre de la section est vide',
            },
        ];
    };

    const addLabelArticle = (type: 'section' | 'lineBreak') => {
        if (!sectionTitleId || !lineBreakId) {
            return;
        }
        const isSection = type === 'section';

        const newLabelArticle = {
            id: generateUUID(), // DraggableId
            originalArticle: {},
            articleId: isSection ? sectionTitleId : lineBreakId,
            label: isSection ? '' : null,
            price: null,
            quantity: 1,
            subTypeId: null,
            invoicingCategory: 'LIB',
        };

        addNewArticle(newLabelArticle, isSection);
    };

    const articleSearchTitle = (
        <Box
            width="23%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            alignSelf="center"
            marginRight="10px"
            sx={{
                height: '40px',
                borderRadius: '10px',
                padding: '10px',
            }}
        >
            {domainArticlesLoaded ? (
                <>
                    <FontAwesomeIcon icon={faSearch} />
                    <Typography sx={{ marginLeft: '10px' }}>
                        Recherche&nbsp;d&apos;articles
                    </Typography>
                </>
            ) : (
                <CircularProgress />
            )}{' '}
        </Box>
    );

    return (
        <>
            {displayHeader && (
                <FlexyHeaderForm
                    label="Paramétrage des articles du package"
                    outlined
                />
            )}
            {/* Search bar */}
            <Box
                display="flex"
                sx={{ margin: '30px 10px' }}
            >
                {articleSearchTitle}

                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="65%"
                >
                    <Box
                        minWidth="100%"
                        marginTop="3px"
                        sx={{ opacity: !domainArticlesLoaded ? 0.4 : 1 }}
                    >
                        <FlexySelect
                            placeholder="Recherche d'articles (Libellé, code article, prix ...) "
                            isDisabled={!domainArticlesLoaded || disabled}
                            onChange={(option: { value: ArticlesArticleRead }) =>
                                onSelectNewArticle(option.value)
                            }
                            options={articleOptions()}
                            noOptionsMessage={() => "Aucun article n'a été défini pour ce domaine"}
                        />
                    </Box>
                </Box>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    {getAvailableInvoicingCategories().length > 0 && (
                        <InvoicingCategoriesFilters
                            invoicingCategories={getAvailableInvoicingCategories()}
                            setFilters={(codes: string[]) => setFilters([...codes])}
                            disabled={disabled}
                        />
                    )}

                    {/* Duplcates merge option */}
                    {hasDuplicates && (
                        <TooltipedButton
                            tooltip="Fusionner les articles dupliqués"
                            onClick={mergeDuplicates}
                            icon={faMerge}
                            disabled={disabled}
                        />
                    )}

                    {/* Add sections and line breaks */}
                    <TooltipedButton
                        tooltip="Ajouter une section"
                        onClick={() => addLabelArticle('section')}
                        icon={faText}
                        disabled={disabled}
                    />

                    <TooltipedButton
                        tooltip="Ajouter un saut de ligne"
                        onClick={() => addLabelArticle('lineBreak')}
                        icon={faParagraph}
                        disabled={disabled}
                    />
                </Box>
            </Box>
            {/* Package content listing */}
            {emptyList && <EmptyLine />}
            <SortableList
                items={articles}
                onChange={setArticles}
                renderItem={(article: ArticleFormItem, index: number) => {
                    const hasErrorOnValidation = errorIndexes.includes(index) && isStepValidating;
                    const key = `package_content_item_${article.id}`;
                    return (
                        <SortableList.Item
                            id={article.id}
                            key={key}
                        >
                            <PackageLine
                                article={article}
                                index={index}
                                context={formContext}
                                hasErrorOnValidation={hasErrorOnValidation}
                                warnings={getWarnings(article.id)}
                                disabled={disabled}
                            />
                        </SortableList.Item>
                    );
                }}
            />
        </>
    );
};

export default ArticlesModule;
