/* eslint-disable no-bitwise */
/** [Draggable list feature scope]
 *
 * Cannot use article id as identifier because article (id) can be duplicated
 *
 * Drag list require an unique identifier - Temporary solution : Generate a UUID using Math.random()
 * A better solution seems to use a RFC4122 library such as https://github.com/uuidjs/uuid
 *
 * Todo : also tweak dnd kit default identifier key 'id' to more explicit 'draggableId'
 */

/**
 * Function that generate a UUID
 * @returns uuid string
 */
export function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

/** Get index displayed in sorted list avatar component */
export const getDisplayedIndex = (index?: number) => {
    if (typeof index === 'number') {
        return (index + 1).toString();
    }

    return ' '; // Index displayed when dragged (space because empty string display blank avatar)
};
