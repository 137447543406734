import * as React from 'react';

import { Box, ListItem, ListItemText } from '@mui/material';
import { displayArticlePriceLabel, formatPrice } from '@europrocurement/l2d-utils';
import { faChevronUp, faEdit, faEuro, faTag, faTrash } from '@fortawesome/pro-solid-svg-icons';
import LineIndexInformation from './LineIndexInformation';
import LineActions from './LineActions';
import QuantityModule from './QuantityModule';
import SortableList from '../../components/SortableList/SortableList';
import useListItemHoverEffect from './useHoverEffect';
import ArticleOverrideSubForm from './ArticleOverrideSubForm';
import LineWarnings from './LineWarnings';
import { ArticleFormKey, Feedback, FormArticle, FormContextType, Warning } from './types';
import LineFeedbacks from './LineFeedbacks';
import useReadOnlyDocuments from '../../components/DocumentsList/useReadOnlyDocuments';

type ComponentProps = {
    article: FormArticle;
    index: number;
    context: FormContextType;
    hasErrorOnValidation: boolean;
    warnings?: Warning[];
    disabled?: boolean;
};

const ArticleLine: React.FC<ComponentProps> = function (props) {
    const { article, index, context, hasErrorOnValidation, warnings, disabled = false } = props;

    const {
        formState: { errors },
        setValue,
        getValues,
    } = context;

    const { actions } = useReadOnlyDocuments(context);

    const listHoverProps = useListItemHoverEffect();

    const currentArticlePath = `articles.${index}`;
    const currentArticleValuePath = (key: ArticleFormKey) => `${currentArticlePath}.${key}`;

    const updateCurrentArticle = (key: ArticleFormKey, value: unknown) => {
        setValue(currentArticleValuePath(key), value);
    };

    const setQuantity = (quantity: number) => {
        updateCurrentArticle('quantity', quantity);
    };

    const inputPrice = article.price;
    const originalPrice = article.originalArticle?.prixUnitaire;
    const isLabelOverrided =
        article.label && article.label.trim() !== article.originalArticle?.libelle;

    /** Check if article is (au forfait) */
    const articleIsFlatRate = article.priceInformations?.isFlatRate === true;

    /** Compute overrided status for price */
    const isPriceOverrided: () => boolean = () => {
        if (!inputPrice) {
            return false;
        }

        if (!originalPrice && inputPrice) {
            return true;
        }

        if (!originalPrice) {
            return false;
        }

        if (parseFloat(inputPrice) === parseFloat(originalPrice)) {
            return false;
        }

        return true;
    };
    const feedBacks: Feedback[] = [
        {
            condition: isLabelOverrided === true,
            tooltip: `Libellé surchargé  (Ancien libéllé : ${article.originalArticle?.libelle}) `,
            icon: faTag,
            feedbackName: 'labelOverride',
        },
        {
            condition: isPriceOverrided(),
            tooltip: `Prix surchargé  (Ancien prix : ${originalPrice ? `${formatPrice(originalPrice)} €` : 'Aucun tarif'})`,
            icon: faEuro,
            feedbackName: 'priceOverride',
        },
    ];

    const [isExpanded, setIsExpanded] = React.useState(false);

    React.useEffect(() => {
        if (hasErrorOnValidation) {
            setIsExpanded(true);
        }
    }, [hasErrorOnValidation]);

    const onEdition = () => {
        setIsExpanded(!isExpanded);
    };

    const onRemove = () => {
        const updatedList = getValues().articles as FormArticle[];
        const newList = updatedList.filter((formArticle) => formArticle.id !== article.id);
        setValue('articles', newList);
        const docIdsToRemove = article.originalArticle?.documents?.map(
            (documentItem) => documentItem.typeDocument?.id,
        );
        actions.removeDocumentsFromCategory('articles', docIdsToRemove as number[]);
    };

    const articleActions = [
        {
            icon: isExpanded ? faChevronUp : faEdit,
            onClick: onEdition,
            actionName: 'edition',
        },
        {
            icon: faTrash,
            onClick: onRemove,
            actionName: 'remove',
        },
    ];

    const lineLabel = article.label?.trim() ? article.label : article.originalArticle?.libelle;
    const linePrice = () => {
        const flatPrice = article.priceInformations?.flatPrice;
        if (articleIsFlatRate && flatPrice) {
            return `(Tarification forfaitaire)`;
        }
        if (isPriceOverrided()) {
            return displayArticlePriceLabel(parseFloat(article.price as string));
        }
        return displayArticlePriceLabel(parseFloat(article.originalArticle?.prixUnitaire ?? ''));
    };

    return (
        <>
            <Box sx={{ marginTop: '17px', marginBottom: 'auto' }}>
                <SortableList.DragHandle />
            </Box>

            <Box width="100%">
                <ul>
                    <ListItem {...listHoverProps}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            width="100%"
                        >
                            <LineIndexInformation
                                article={article}
                                context={context}
                            />
                            <ListItemText
                                primary={
                                    <div
                                        style={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            maxWidth: '300px',
                                        }}
                                    >
                                        {lineLabel}
                                    </div>
                                }
                                secondary={linePrice()}
                            />
                            <LineFeedbacks
                                feedbacks={feedBacks}
                                lineIndex={index}
                            />
                            <QuantityModule
                                article={article}
                                setQuantity={setQuantity}
                                inputName={`articles.${index}.quantity`}
                                errors={errors}
                            />
                            <LineWarnings
                                warnings={warnings}
                                lineIndex={index}
                            />
                            <LineActions
                                actions={articleActions}
                                lineIndex={index}
                                disabled={disabled}
                            />
                        </Box>
                    </ListItem>
                </ul>
                {isExpanded && !disabled && (
                    <ArticleOverrideSubForm
                        article={article}
                        articlePath={currentArticlePath}
                        context={context}
                    />
                )}
            </Box>
        </>
    );
};

export default ArticleLine;
